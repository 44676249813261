.yusra_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1.5rem 6rem;
}

.yusra_navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}


.yusra_navbar-links_logo p {
    margin-right: 2rem;
    width: 62.56px;
    height: 16.02px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    letter-spacing: 0.07em;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
}

.yusra_navbar-links_logo img {
    /* margin-top: 1rem;
    margin-left: 3rem; */

    display: flex;
    width: 55%;
    height: 55%;
    margin-left: 0rem;
}

.yusra_navbar-links_container {
    display: flex;
    flex-direction: row;
    gap: 4rem;
}

.yusra_navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.link-class {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
.yusra_navbar-links_container p,
.yusra_navbar-sign p,
.yusra_navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.yusra_navbar-sign button,
.yusra_navbar-menu_container button {
    padding: 0.5rem 1rem;
    color:rgb(0, 20, 68);
    background: #d0f5fe;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.yusra_navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.yusra_navbar-menu svg {
    cursor: pointer;
}

.yusra_navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;


    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.yusra_navbar-menu_container p {
    margin: 1rem 0;
}

.yusra_navbar-menu_container-links-sign {
    display: none;

}
.img-nav{
    height:30px;
}

.button-nav{
     color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
/*bootstrap*/
bg-light {
    background-color: transparent !important;
}

@media screen and (max-width: 1050px) {
    .yusra_navbar-links_container {
        display: none;
    }

    .yusra_navbar-menu {
        display: flex;
    }

    .yusra_navbar-menu_container-links{
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 700px) {
    .yusra_navbar {
        padding: 2rem 4rem;
    }

       .yusra_navbar-menu_container-links{
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 550px) {
    .yusra_navbar {
        padding: 2rem;
    }

    .yusra_navbar-sign {
        display: none;
    }

    .yusra_navbar-menu_container {
        top: 20px;
    }

    .yusra_navbar-menu_container-links-sign {
        display: block;
        flex-wrap: wrap;
    }

    .yusra_navbar-menu_container-links{
        display: flex;
        flex-wrap: wrap;
    }
}