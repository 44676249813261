.yusra__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}

.yusra__features-container__feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}

.yusra__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 17.3px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.yusra__features-container__feature-title div {
    width: 42px;
    height: 3.5px;
    /* background: var(--gradient-bar); */
    background: linear-gradient(124deg, #1971ff, #00D1FF, #b7edff, #d0f5fe);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.yusra__features-container_feature-text {
    flex: 2;
    max-width: 400px;
    display: flex;
}

.yusra__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .yusra__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .yusra__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .yusra__features-container__feature {
        margin: 1rem 0;
    }
}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}