.yusra_partner_card {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
}
.yusra_partner-card .marquee {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
.yusra_partner_card div {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}
.yusra_partner_card h1 {
    padding: 5rem 2rem .5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 32.5px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #fff;
    margin-top: -5rem;
}

.yusra_partner_card img{
    display: flex;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 50%;
}

.yusra_partner {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.yusra_partner div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem 0rem -2rem 0rem;

    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 500px) {
    .yusra_partner_card {

        padding: 1rem 2rem;
        justify-content: center;
        align-items: center;

        display: flex;
        flex-direction: column;
        border-radius: 25px;
        width: 100%;
        margin: auto;

    }

    .yusra_partner_card h1 {
        flex-direction: column;
        /*display: flex;*/
        text-align: center;
        font-size: 24px;


    }

    .yusra_partner div {
        margin: 0 0 3rem;
    }

    .yusra_partner img{

        display: flex;
        width: 70%;
        min-width: 50%;
        height: 70%;
        min-height: 50%;
        /*margin-top: 5rem;*/

    }


}



@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}