.yusra__header {
    display: flex;
}

.yusra__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 10rem;
}

.yusra__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color: #d0f5fe;
    background: linear-gradient(124deg, #1971ff, #00D1FF, #b7edff, #d0f5fe);
    background-size: 200% 200%;
    -webkit-animation: rainbow 15s ease infinite;
    -z-animation: rainbow 15s ease infinite;
    -o-animation: rainbow 15s ease infinite;
    animation: rainbow 15s ease infinite;
    background-clip: text;
    -webkit-background-clip:text;
    transition: color .5s ease-in-out;
}
.yusra__header-content:hover h1{
    color:rgba(0,0,0,0);
}

.yusra__header-content p {
    font-family: var(--font-family);
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    font-style: normal;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.yusra__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    
    display:flex;
    flex-direction: row;
}

.yusra__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-size: 20px;
    font-family: var(--font-family);
    border-radius: 5px 0px 0px 5px;
    line-height: 27px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    font-weight: 800;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.yusra__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #d0f5fe;
    border: 2px solid #d0f5fe;
    padding: 0 1rem;
    color: rgb(0, 20, 68);
    cursor: pointer;
    outline: none;
    font-weight: 800;
    /*border-top-right-radius: 5px;*/
    /*border-bottom-right-radius: 5px;*/
    border-radius: 5px;
}

.yusra__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.yusra__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.yusra__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: left;
}

.yusra__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.yusra__header-image img {
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1050px) {
    .yusra__header {
        flex-direction: column;

    }
    .yusra__header-content {
        margin: 0 0 3rem;
    }

}

@media screen and (max-width: 650px) {
    .yusra__header h1 {
        font-size: 48px;
        line-height: 60px;

    }
    .yusra__header p {
        font-size: 16px;
        line-height: 24px;

    }
    
    .yusra__header-content__people {
        flex-direction: column;
    }

    .yusra__header-content__people p {
        margin: 0;
    }
    
    .yusra__header-content__input input,
    .yusra__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}


@media screen and (max-width: 490px) {
    .yusra__header h1 {
        font-size: 36px;
        line-height: 48px;

    }
    .yusra__header p {
        font-size: 14px;
        line-height: 24px;

    }

    .yusra__header-content__input input,
    .yusra__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}