.yusra_brand {
    padding: 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 7.5rem;
    align-items: center;
}

  .yusra_brand img{
        display: flex;
        width: 50%;
        min-width: 50%;
        height: 50%;
        min-height: 50%;
        /*margin-top: 5rem;*/


    }

.yusra_brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem 0rem -2rem 0rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.yusra_brand-techs {
    margin: -1rem 0rem -2rem 0rem;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yusra_brand-techs h1 {
    /* font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #fff; */

    font-family: var(--font-family);
    font-style: normal;
    font-weight: 900;
    font-size: 32.5px;
    line-height: 48px;
    margin-bottom: 2rem;
    text-shadow: 1px 1px var(--gradient-text);

}

@media screen and (max-width: 490px) {

    .yusra_brand-techs h1 {
        flex-direction: column;
        display: flex;
        text-align: center;

    }
    .yusra_brand div {
        margin: 0 0 3rem;
    }

    .yusra_brand img{

        display: flex;
        width: 40%;
        min-width: 40%;
        height: 40%;
        min-height: 40%;
        /*margin-top: 5rem;*/

    }


}


@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}