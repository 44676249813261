.yusra__whatyusra {
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    /*margin: 4rem 6rem;*/
    margin-top: 4rem;
    margin-bottom: 4rem;
    margin-left: 4rem;
    margin-right: 4rem;

    padding: 5rem;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.yusra__whatyusra-feature {
    display: flex;
}

.yusra__whatyusra-feature .yusra__features-continer__feature {
    margin: 0;
}


.yusra__whatyusra-feature .yusra__features-continer__feature-text {
    max-width: 700px;
}

.yusra__whatyusra-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 1.5rem 1rem 2rem;
}

.yusra__whatyusra-heading h1 {
    font-size: 36px;
    line-height: 45px;
    font-weight: 800;
    /* max-width: 510px; */
    font-family: var(--font-family);
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.yusra__whatyusra-heading p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin-right: 2rem;

    color: var(--color-subtext);
    cursor: pointer;
}


.yusra__whatyusra-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;

}

.yusra__whatyusra-container .yusra__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.yusra__whatyusra-container .yusra__features-container_feature-text {
    margin-top: 1rem;
}

@media screen and (max-width: 850px) {
    .yusra__whatyusra-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .yusra__whatyusra-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    .yusra__whatyusra {
        display: flex;
        flex-direction: column;
        border-radius: 25px;
        /*margin: 4rem 6rem;*/
        margin-top: 4rem;
        margin-bottom: 4rem;
        margin-left: 0rem;
        margin-right: 0rem;
    }
    /* Customizing feature component as per needs */
    .yusra__whatyusra-feature .yusra__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .yusra__whatyusra-feature .yusra__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .yusra__whatyusra-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .yusra__whatyusra-container .yusra__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

.yusra__space {
    margin: 0rem 35rem 0rem 0rem; 
}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}