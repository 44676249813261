* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    /* background-color: var(--color-bg); */
    background: var(--color-bg);
}
body::-webkit-scrollbar {
    background-color: rgb(9, 25, 49);
    width: 10px
}
body::-webkit-scrollbar-track {
    background-color: rgb(9, 25, 49);
    border-radius: 20px;
    
}
body::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: rgb(161, 223, 250);
    /* background-color: #00AEEF; */
    background-clip: padding-box;
}
body::-webkit-scrollbar-thumb:hover {
    background-color: lightgray;
}


a {
    color: unset;
    text-decoration: none;
}

/* .gradient__bg
    {


    background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);


    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);


    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);


    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);


    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
    
} */

.gradient__text {
    background: linear-gradient(124deg, #1971ff, #00D1FF, #b7edff, #d0f5fe);
    /* background:var(--gradient-text); */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__padding-features {
    padding: 4rem 4rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
@keyframes scale-up-center {
0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}

@media screen and (max-width: 700px) {
.section__padding {
    padding: 4rem;
}

.section__margin {
    margin: 4rem;
}
}

/* mobile devices */
@media screen and (max-width: 550px) {
.section__padding {
    padding: 4rem 2rem;
}

.section__margin {
    margin: 4rem 2rem;
}
}

