.yusra_hub {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}



.yusra_hub-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0 0 2rem 0;
}

.yusra_hub-heading h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 900;
    font-size: 32.5px;
    line-height: 48px;
    margin-bottom: 2rem;
    text-shadow: 1px 1px var(--gradient-text);

}

.yusra_hub-heading p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: var(--color-subtext);
    margin-top: 2rem;
}

.yusra_hub-container {
    flex: 1;
    display: flex;
    /*margin-top: 5.5rem;*/
    /*margin-left: 5.5rem;*/
    /*margin-bottom: auto;*/
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    
}
/*.yusra_hub-container  p {*/
/*  font-family: var(--font-family);*/
/*    font-weight: normal;*/
/*    font-size: 15px;*/
/*    line-height: 27px;*/
/*    font-style: normal;*/
/*    color: var(--color-text);*/
/*    margin-top: 1.5rem;*/
/*}*/


/*.yusra_hub-container-main-h1 {*/

/*    font-family: var(--font-family);*/
/*    font-weight: 800;*/
/*    font-size: 30px;*/
/*    line-height: 30px;*/
/*    letter-spacing: -0.04em;*/
/*    color: #d0f5fe;*/
/*    background: linear-gradient(124deg, #1971ff, #00D1FF, #b7edff, #d0f5fe);*/
/*    background-size: 200% 200%;*/
/*    -webkit-animation: rainbow 15s ease infinite;*/
/*    -z-animation: rainbow 15s ease infinite;*/
/*    -o-animation: rainbow 15s ease infinite;*/
/*    animation: rainbow 15s ease infinite;*/
/*    background-clip: text;*/
/*    -webkit-background-clip:text;*/
/*    transition: color .5s ease-in-out;*/
/*}*/

.img_holder {
    align-items: center;
}

.binary_flow_4k {
    flex: 1;
    display: flex;
    max-width: 100%;
    border-radius: 25px;
}

@media screen and (max-width: 1500px) {
    .yusra_hub {
        flex-direction: column;
    }

    .img_holder {
        align-items: center;
        justify-content: space-between;
    }

    .binary_flow_4k {
        margin-left: 2rem;
        margin-bottom: -4rem;
        flex: 1;
        display: flex;
        max-width: 90%;
        align-items: center;
        border-radius: 25px;
    }

    .yusra_hub-heading {
        margin: 0 0 2rem 0;
    }

    .yusra_hub-container {
        flex: 1;
        display: flex;
        margin-top: 5.5rem;
        margin-left: 5.5rem;
        margin-bottom: auto;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

    }
}


@media screen and (max-width: 550px) {

    .yusra_hub {
        flex-direction: column;
    }

    .img_holder {
        align-items: center;
        justify-content: space-between;
    }

    .binary_flow_4k {
        margin-left: 0rem;
        margin-bottom: -4rem;
        flex: 1;
        display: flex;
        max-width: 100%;
        align-items: center;
        border-radius: 25px;
    }
    
    .yusra_hub-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }

    .yusra_hub-container {
        flex: 1;
        display: flex;
        margin-top: 5.5rem;
        margin-left: 5.5rem;
        margin-bottom: auto;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

    }
}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@-o-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}