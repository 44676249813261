
.coming_soon_body {
  /* background: url(../img/cover.jpg) no-repeat center center fixed; */
  background-size: auto;
  color: #fff;
  text-align: center;
  font-family: 'Quicksand', sans-serif; }

.cover-container {
  margin-right: auto;
  margin-left: auto; }

/* Padding for spacing */
.inner {
  padding: 30px; }

.masthead-brand {
  margin-top: 10px;
  margin-bottom: 10px; }

.nav-masthead {
  text-align: center;
  display: block; }


@media (min-width: 768px) {
  .masthead-brand {
    float: left; }
  .nav-masthead {
    float: right; } }

.cover-heading {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-size: 2rem;
  margin-bottom: 5rem; }

@media (min-width: 768px) {
  .cover-heading {
    font-size: 3.4rem;
    letter-spacing: 15px; } }

.cover-copy {
  max-width: 500px;
  margin: 0 auto 3rem; }

