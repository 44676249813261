.yusra_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.yusra_footer_heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.yusra_footer_heading h1 {
    margin-left: auto;
    margin-right: auto;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 75px;
    width: 75%;
    
}


.yusra_footer_btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.yusra_footer_btn button {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
    background-color: var(--color-footer);
    cursor: pointer;
}

.yusra_footer_btn a {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.yusra_footer_links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.yusra_footer_links div {
    width: 250px;
    margin: 1rem;
}

.yusra_footer-links_logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.yusra_footer_links_logo img {
    display: flex;
    margin-left: 7rem;
    width: 55%;
    height: 55%;
}

.yusra_footer_links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.yusra_footer_links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.yusra_footer_links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}
.link_class2 {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.yusra_footer_links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
}

.yusra_footer_copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    display: flex;
    gap: 4rem;
}

.yusra_footer_copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

.mbe_logo{
    margin-top: 1rem;
    margin-left: -9rem;
}

@media screen and (max-width: 1550px) {
    .yusra_footer_heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
    .yusra_footer_links_logo img {
        display: flex;
        margin-left: 0rem;
        width: 55%;
        height: 55%;
    }
}

@media screen and (max-width: 850px) {
    .yusra_footer_heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
    .yusra_footer_links_logo img {
        display: flex;
        margin-left: 0rem;
        width: 55%;
        height: 55%;
    }
    .yusra_footer_copyright p {
        margin-top: 2rem;
        text-align: center;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        display: flex;
    }
}

@media screen and (max-width: 550px) {
    .yusra_footer_heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .yusra_footer_links div {
        margin: 1rem 0;
    }

    .yusra_footer_btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .yusra_footer_links_logo img {
        display: flex;
        margin-left: 0rem;
        width: 35%;
        height: 35%;
    }
    .yusra_footer_copyright p {
        margin-top: 2rem;
        text-align: center;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        display: flex;
    }
}

@media screen and (max-width: 400px) {
    .yusra_footer_heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
    .yusra_footer_copyright p {
        margin-top: 2rem;
        text-align: center;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        display: flex;
    }
}